import axios from "axios";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST,
  headers: { "X-COMPANY-UUID": localStorage.getItem("COMPANY-UUID") },
});

const urlList = {
  urlRoot: "/api/v1/xero/",
};

export default {
  getXeroLoginInfo() {
    let url = urlList.urlRoot + "login/";
    return apiClient.get(url);
  },

  revokeXeroToken(formData) {
    let url = urlList.urlRoot + "revoke-token/";
    return apiClient.post(url, formData);
  },
};
