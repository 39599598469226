import axios from "axios";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST,
  headers: { "X-COMPANY-UUID": localStorage.getItem("COMPANY-UUID") },
});

const urlList = {
  urlRoot: "/api/v1/settings/admin/",
  invoiceSettingsUrlRoot: "/api/v1/settings/invoice/",
  invoiceSenderEmailUrlRoot: "/api/v1/settings/invoice-sender-emails/",
  companyJobSettingsUrlRoot: "/api/v1/settings/job/",
  companyWofSettingsUrlRoot: "/api/v1/settings/wof/",
  companyNotificationSettingsUrlRoot: "/api/v1/settings/company-notification/",
  customerNotificationSettingsUrlRoot:
    "/api/v1/settings/customer-notification/",
  xeroSettingsUrlRoot: "/api/v1/settings/xero/",
};

export default {
  getCustomerSettings() {
    let url = urlList.urlRoot + "customer/";
    return apiClient.get(url);
  },

  updateCustomerSettings(formData) {
    let url = urlList.urlRoot + "customer/";
    return apiClient.post(url, formData);
  },

  // job settings
  retrieveCompanyJobSettings() {
    let url = urlList.companyJobSettingsUrlRoot + "retrieve/";
    return apiClient.get(url);
  },

  fetchCompanyJobSettingFormData() {
    let url = urlList.companyJobSettingsUrlRoot + "form-data/";
    return apiClient.get(url);
  },

  updateCompanyJobSettings(formData) {
    let url = urlList.companyJobSettingsUrlRoot + "update/";
    return apiClient.patch(url, formData);
  },

  // wof settings
  retrieveCompanyWofSettings() {
    let url = urlList.companyWofSettingsUrlRoot + "retrieve/";
    return apiClient.get(url);
  },

  updateCompanyWofSettings(formData) {
    let url = urlList.companyWofSettingsUrlRoot + "update/";
    return apiClient.patch(url, formData);
  },

  // invoice settings
  getInvoiceSettingsFormData() {
    let url = urlList.invoiceSettingsUrlRoot + "form-data/";
    return apiClient.get(url);
  },

  retrieveInvoiceSettings() {
    let url = urlList.invoiceSettingsUrlRoot + "retrieve/";
    return apiClient.get(url);
  },

  updateInvoiceSettings(formData) {
    let url = urlList.invoiceSettingsUrlRoot + "update/";
    return apiClient.patch(url, formData);
  },

  // invoice sender email settings
  getInvoiceSenderEmailListWithoutPagination() {
    let url = urlList.invoiceSenderEmailUrlRoot;
    return apiClient.get(url);
  },

  addInvoiceSenderEmail(formData) {
    let url = urlList.invoiceSenderEmailUrlRoot;
    return apiClient.post(url, formData);
  },

  updateInvoiceSenderEmail(objId, formData) {
    let url = urlList.invoiceSenderEmailUrlRoot + `${objId}/`;
    return apiClient.patch(url, formData);
  },

  deleteInvoiceSenderEmail(objId) {
    let url = urlList.invoiceSenderEmailUrlRoot + `${objId}/`;
    return apiClient.delete(url);
  },

  getInvoiceSenderEmailFormData() {
    let url = urlList.invoiceSenderEmailUrlRoot + "form-data/";
    return apiClient.get(url);
  },

  // xero settings
  retrieveXeroSettings() {
    let url = urlList.xeroSettingsUrlRoot + "retrieve/";
    return apiClient.get(url);
  },

  updateXeroSettings(formData) {
    let url = urlList.xeroSettingsUrlRoot + "update/";
    return apiClient.patch(url, formData);
  },

  // notification settings
  retrieveCompanyNotificationSettings() {
    let url = urlList.companyNotificationSettingsUrlRoot + "retrieve/";
    return apiClient.get(url);
  },

  updateCompanyNotificationSettings(formData) {
    let url = urlList.companyNotificationSettingsUrlRoot + "update/";
    return apiClient.patch(url, formData);
  },

  retrieveCustomerNotificationSettings() {
    let url = urlList.customerNotificationSettingsUrlRoot + "retrieve/";
    return apiClient.get(url);
  },

  updateCustomerNotificationSettings(formData) {
    let url = urlList.customerNotificationSettingsUrlRoot + "update/";
    return apiClient.patch(url, formData);
  },

  retrieveDefaultCustomNotificationMessage() {
    let url =
      urlList.customerNotificationSettingsUrlRoot + "default-custom-messages/";
    return apiClient.get(url);
  },
};
