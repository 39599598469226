import axios from "axios";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST,
  headers: { "X-COMPANY-UUID": localStorage.getItem("COMPANY-UUID") },
});

const urlList = {
  urlRoot: "/api/v1/",
};

export default {
  // vehicle wof report
  getVehiclesWofReport(
    perPage,
    page,
    searchQuery,
    wofExpiryDateFrom,
    wofExpiryDateTo,
    jobWithinAYear,
    historicalReminder,
    bookedOnJob,
    suppressedWofReminders
  ) {
    let url =
      urlList.urlRoot +
      "reports/vehicles/wof/" +
      "?page_size=" +
      perPage +
      "&page=" +
      page;

    if (searchQuery) {
      url += "&search=" + searchQuery;
    }
    if (wofExpiryDateFrom && wofExpiryDateTo) {
      url +=
        "&wof_expiry_date_from=" +
        wofExpiryDateFrom +
        "&wof_expiry_date_to=" +
        wofExpiryDateTo;
    }
    if (jobWithinAYear) {
      url += "&job_within_a_year=" + jobWithinAYear;
    }
    if (historicalReminder) {
      url += "&historical_reminder=" + historicalReminder;
    }
    url +=
      "&booked_on_job=" +
      bookedOnJob +
      "&suppressed_wof_reminders=" +
      suppressedWofReminders;

    return apiClient.get(url);
  },

  generateVehiclesWofReportPDF(
    searchQuery,
    wofExpiryDateFrom,
    wofExpiryDateTo,
    jobWithinAYear,
    historicalReminder,
    bookedOnJob,
    suppressedWofReminders
  ) {
    let url =
      urlList.urlRoot +
      "reports/vehicles/wof/generate-pdf/?search=" +
      searchQuery;

    if (wofExpiryDateFrom && wofExpiryDateTo) {
      url +=
        "&wof_expiry_date_from=" +
        wofExpiryDateFrom +
        "&wof_expiry_date_to=" +
        wofExpiryDateTo;
    }
    if (jobWithinAYear) {
      url += "&job_within_a_year=" + jobWithinAYear;
    }
    if (historicalReminder) {
      url += "&historical_reminder=" + historicalReminder;
    }
    url +=
      "&booked_on_job=" +
      bookedOnJob +
      "&suppressed_wof_reminders=" +
      suppressedWofReminders;

    return apiClient.post(url);
  },

  checkVehiclesWofReportPDFTaskStatus(formData) {
    let url = urlList.urlRoot + "reports/vehicles/wof/pdf-task-status/";
    return apiClient.post(url, formData);
  },

  generateVehiclesWofReportExcel(
    searchQuery,
    wofExpiryDateFrom,
    wofExpiryDateTo,
    jobWithinAYear,
    historicalReminder,
    bookedOnJob,
    suppressedWofReminders
  ) {
    let url =
      urlList.urlRoot +
      "reports/vehicles/wof/generate-excel/?search=" +
      searchQuery;

    if (wofExpiryDateFrom && wofExpiryDateTo) {
      url +=
        "&wof_expiry_date_from=" +
        wofExpiryDateFrom +
        "&wof_expiry_date_to=" +
        wofExpiryDateTo;
    }
    if (jobWithinAYear) {
      url += "&job_within_a_year=" + jobWithinAYear;
    }
    if (historicalReminder) {
      url += "&historical_reminder=" + historicalReminder;
    }
    url +=
      "&booked_on_job=" +
      bookedOnJob +
      "&suppressed_wof_reminders=" +
      suppressedWofReminders;

    return apiClient.post(url);
  },

  checkVehiclesWofReportExcelTaskStatus(formData) {
    let url = urlList.urlRoot + "reports/vehicles/wof/excel-task-status/";
    return apiClient.post(url, formData);
  },

  notifyVehicleOwnerWofExpiration(vehicleUUID, formData) {
    let url =
      urlList.urlRoot + `reports/vehicles/${vehicleUUID}/wof/notify-owner/`;
    return apiClient.post(url, formData);
  },

  notifyVehicleOwnerWofExpirationInBulk(formData) {
    let url = urlList.urlRoot + `reports/vehicles/wof/notify-owners-bulk/`;
    return apiClient.post(url, formData);
  },

  suppressWofReminder(formData) {
    let url = urlList.urlRoot + `reports/vehicles/wof/suppress-reminder/`;
    return apiClient.post(url, formData);
  },

  suppressWofReminderBulk(formData) {
    let url = urlList.urlRoot + `reports/vehicles/wof/suppress-reminder-bulk/`;
    return apiClient.post(url, formData);
  },

  // vehicle service reminder report
  getVehiclesServiceReminderReport(
    perPage,
    page,
    searchQuery,
    serviceDueDateFrom,
    serviceDueDateTo,
    jobWithinAYear,
    historicalReminder,
    bookedOnJob,
    suppressedServiceReminders
  ) {
    let url =
      urlList.urlRoot +
      "reports/vehicles/service-reminders/list/" +
      "?page_size=" +
      perPage +
      "&page=" +
      page;

    if (searchQuery) {
      url += "&search=" + searchQuery;
    }
    if (serviceDueDateFrom && serviceDueDateTo) {
      url +=
        "&service_due_date_from=" +
        serviceDueDateFrom +
        "&service_due_date_to=" +
        serviceDueDateTo;
    }
    if (jobWithinAYear) {
      url += "&job_within_a_year=" + jobWithinAYear;
    }
    if (historicalReminder) {
      url += "&historical_reminder=" + historicalReminder;
    }

    url +=
      "&booked_on_job=" +
      bookedOnJob +
      "&suppressed_service_reminders=" +
      suppressedServiceReminders;

    return apiClient.get(url);
  },

  generateVehiclesServiceReminderReportPDF(
    searchQuery,
    serviceDueDateFrom,
    serviceDueDateTo,
    jobWithinAYear,
    historicalReminder,
    bookedOnJob
  ) {
    let url =
      urlList.urlRoot +
      "reports/vehicles/service-reminders/generate-pdf/?search=" +
      searchQuery;

    if (serviceDueDateFrom && serviceDueDateTo) {
      url +=
        "&service_due_date_from=" +
        serviceDueDateFrom +
        "&service_due_date_to=" +
        serviceDueDateTo;
    }
    if (jobWithinAYear) {
      url += "&job_within_a_year=" + jobWithinAYear;
    }
    if (historicalReminder) {
      url += "&historical_reminder=" + historicalReminder;
    }
    url += "&booked_on_job=" + bookedOnJob;

    return apiClient.post(url);
  },

  checkVehiclesServiceReminderReportPDFTaskStatus(formData) {
    let url =
      urlList.urlRoot + "reports/vehicles/service-reminders/pdf-task-status/";
    return apiClient.post(url, formData);
  },

  generateVehiclesServiceReminderReportExcel(
    searchQuery,
    serviceDueDateFrom,
    serviceDueDateTo,
    jobWithinAYear,
    historicalReminder,
    bookedOnJob
  ) {
    let url =
      urlList.urlRoot +
      "reports/vehicles/service-reminders/generate-excel/?search=" +
      searchQuery;

    if (serviceDueDateFrom && serviceDueDateTo) {
      url +=
        "&service_due_date_from=" +
        serviceDueDateFrom +
        "&service_due_date_to=" +
        serviceDueDateTo;
    }
    if (jobWithinAYear) {
      url += "&job_within_a_year=" + jobWithinAYear;
    }
    if (historicalReminder) {
      url += "&historical_reminder=" + historicalReminder;
    }
    url += "&booked_on_job=" + bookedOnJob;

    return apiClient.post(url);
  },

  checkVehiclesServiceReminderReportExcelTaskStatus(formData) {
    let url =
      urlList.urlRoot + "reports/vehicles/service-reminders/excel-task-status/";
    return apiClient.post(url, formData);
  },

  notifyVehicleOwnerServiceReminderExpiration(vehicleUUID, formData) {
    let url =
      urlList.urlRoot +
      `reports/vehicles/service-reminders/${vehicleUUID}/notify-owner/`;
    return apiClient.post(url, formData);
  },

  notifyVehicleOwnerServiceReminderExpirationInBulk(formData) {
    let url =
      urlList.urlRoot +
      `reports/vehicles/service-reminders/notify-owners-bulk/`;
    return apiClient.post(url, formData);
  },

  suppressServiceReminder(formData) {
    let url =
      urlList.urlRoot + `reports/vehicles/service-reminders/suppress-reminder/`;
    return apiClient.post(url, formData);
  },

  suppressServiceReminderBulk(formData) {
    let url =
      urlList.urlRoot +
      `reports/vehicles/service-reminders/suppress-reminder-bulk/`;
    return apiClient.post(url, formData);
  },

  // vehicle wof conflict of interest report
  getVehiclesWofConflictOfInterestReport(
    perPage,
    page,
    searchQuery,
    wofJobDateFrom,
    wofJobDateTo
  ) {
    let url =
      urlList.urlRoot +
      "reports/wof-conflict-of-interest/wof-conflict-data/" +
      "?page_size=" +
      perPage +
      "&page=" +
      page;

    if (searchQuery) {
      url += "&search=" + searchQuery;
    }
    if (wofJobDateFrom && wofJobDateTo) {
      url += "&start_at=" + wofJobDateFrom + "&end_at=" + wofJobDateTo;
    }

    return apiClient.get(url);
  },

  generateVehiclesWofConflictOfInterestReportPDF(
    searchQuery,
    wofJobDateFrom,
    wofJobDateTo
  ) {
    let url =
      urlList.urlRoot +
      "reports/wof-conflict-of-interest/wof-conflict-data/generate-pdf/?search=" +
      searchQuery;

    if (wofJobDateFrom && wofJobDateTo) {
      url += "&start_at=" + wofJobDateFrom + "&end_at=" + wofJobDateTo;
    }

    return apiClient.post(url);
  },

  checkVehiclesWofConflictOfInterestReportPDFTaskStatus(formData) {
    let url =
      urlList.urlRoot +
      "reports/wof-conflict-of-interest/wof-conflict-data/pdf-task-status/";
    return apiClient.post(url, formData);
  },

  generateVehiclesWofConflictOfInterestReportExcel(
    searchQuery,
    wofJobDateFrom,
    wofJobDateto
  ) {
    let url =
      urlList.urlRoot +
      "reports/wof-conflict-of-interest/wof-conflict-data/generate-excel/?search=" +
      searchQuery;

    if (wofJobDateFrom && wofJobDateto) {
      url += "&start_at=" + wofJobDateFrom + "&end_at=" + wofJobDateto;
    }

    return apiClient.post(url);
  },

  checkVehiclesWofConflictOfInterestReportExcelTaskStatus(formData) {
    let url =
      urlList.urlRoot +
      "reports/wof-conflict-of-interest/wof-conflict-data/excel-task-status/";
    return apiClient.post(url, formData);
  },
};
