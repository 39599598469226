import axios from "axios";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST,
  headers: { "X-COMPANY-UUID": localStorage.getItem("COMPANY-UUID") },
});

const urlList = {
  urlRoot: "/api/v1/",
};

export default {
  retrieveCompanyUser(companyUserUUID) {
    let url = urlList.urlRoot + `company/users/${companyUserUUID}/`;
    return apiClient.get(url);
  },

  getCurrentCompanyUserInfo() {
    let url = urlList.urlRoot + "company/users/current/info/";
    return apiClient.get(url);
  },

  updateCompanyUser(companyUserUUID, formData) {
    let url = urlList.urlRoot + `company/users/${companyUserUUID}/`;
    return apiClient.patch(url, formData);
  },

  getCompanyUsersList(perPage, page, searchQuery, permissionRoles, statuses) {
    let url =
      urlList.urlRoot + "company/users/?page_size=" + perPage + "&page=" + page;

    if (searchQuery) {
      url += "&search=" + searchQuery;
    }
    if (permissionRoles) {
      url += "&permission_roles=" + permissionRoles;
    }
    if (statuses) {
      url += "&statuses=" + statuses;
    }

    return apiClient.get(url);
  },

  getCompanyUserFormData() {
    let url = urlList.urlRoot + "company/users/form-data/";
    return apiClient.get(url);
  },

  getUserCompanyList() {
    let url = urlList.urlRoot + "company/users/company-list/";
    return apiClient.get(url);
  },

  deleteCompanyUser(companyUserUUID) {
    let url = urlList.urlRoot + `company/users/${companyUserUUID}/`;
    return apiClient.delete(url);
  },

  suspendCompanyUser(companyUserUUID) {
    let url = urlList.urlRoot + `company/users/${companyUserUUID}/suspend/`;
    return apiClient.post(url);
  },

  activeCompanyUser(companyUserUUID) {
    let url = urlList.urlRoot + `company/users/${companyUserUUID}/active/`;
    return apiClient.post(url);
  },

  selectCompany(formData) {
    let url = urlList.urlRoot + `company/users/select-company/`;
    return apiClient.post(url, formData);
  },
};
