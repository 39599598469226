import ServiceCompany from "../../service/company/ServiceCompany.js";
import { Notification } from "element-ui";

export const namespaced = true;

export const state = {
  adminNotificationsList: [],
};

export const mutations = {
  SET_COMPANY_ADMIN_NOTIFICATIONS_LIST(state, data) {
    state.adminNotificationsList = JSON.parse(JSON.stringify(data));
  },

  DELETE_COMPANY_ADMIN_NOTIFICATION(state, formData) {
    state.adminNotificationsList = state.adminNotificationsList.filter(
      (element) => {
        if (
          element.obj_uuid != formData.obj_uuid ||
          element.msg_type != formData.msg_type
        ) {
          return element;
        }
      }
    );
  },
};

export const actions = {
  retrieveCompany({}, companyUUID) {
    return ServiceCompany.retrieveCompany(companyUUID)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  updateCompany({}, { companyUUID, formData }) {
    return ServiceCompany.updateCompany(companyUUID, formData)
      .then((resp) => {
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Company updated successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.name) {
            message = "Name: " + err.response.data.name;
          } else if (err.response.data.email) {
            message = "Email: " + err.response.data.email;
          } else if (err.response.data.phone_no) {
            message = "Phone No.: " + err.response.data.phone_no;
          } else if (err.response.data.unit_no) {
            message = "Unit No.: " + err.response.data.unit_no;
          } else if (err.response.data.street_number) {
            message = "Street Number: " + err.response.data.street_number;
          } else if (err.response.data.street_name) {
            message = "Street Name: " + err.response.data.street_name;
          } else if (err.response.data.city) {
            message = "City: " + err.response.data.city;
          } else if (err.response.data.region) {
            message = "Region: " + err.response.data.region;
          } else if (err.response.data.post_code) {
            message = "Post Code: " + err.response.data.post_code;
          } else if (err.response.data.country) {
            message = "Country: " + err.response.data.country;
          } else if (err.response.data.logo) {
            message = "Logo: " + err.response.data.logo;
          } else if (err.response.data.website) {
            message = "Website: " + err.response.data.website;
          } else if (err.response.data.timestamp_format) {
            message = "Timestamp Format: " + err.response.data.timestamp_format;
          } else if (err.response.data.timezone) {
            message = "Timezone: " + err.response.data.timezone;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  getCompanyFormData({}) {
    return ServiceCompany.getCompanyFormData()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getCompanyAdminNotificationList({ commit }) {
    return ServiceCompany.getCompanyAdminNotificationList()
      .then((resp) => {
        commit(
          "SET_COMPANY_ADMIN_NOTIFICATIONS_LIST",
          resp.data.admin_notifications
        );
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  deleteCompanyAdminNotification({ commit }, formData) {
    return ServiceCompany.deleteCompanyAdminNotification(formData)
      .then((resp) => {
        commit("DELETE_COMPANY_ADMIN_NOTIFICATION", formData);
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Notification dismissed",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },
};
