import axios from "axios";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST,
  headers: { "X-COMPANY-UUID": localStorage.getItem("COMPANY-UUID") },
});

const urlList = {
  urlRoot: "/api/v1/companies/",
};

export default {
  retrieveCompany(companyUUID) {
    let url = urlList.urlRoot + `${companyUUID}/`;
    return apiClient.get(url);
  },

  updateCompany(companyUUID, formData) {
    let url = urlList.urlRoot + `${companyUUID}/`;
    return apiClient.patch(url, formData);
  },

  getCompanyFormData() {
    let url = urlList.urlRoot + "form-data/";
    return apiClient.get(url);
  },

  getCompanyAdminNotificationList() {
    let url = urlList.urlRoot + "admin-notifications/";
    return apiClient.get(url);
  },

  deleteCompanyAdminNotification(formData) {
    let url = urlList.urlRoot + "admin-notifications/delete/";
    return apiClient.delete(url, { data: formData });
  },
};
