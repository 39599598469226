import Vue from "vue";

Vue.filter("lowercase", function (value) {
  return value.toLowerCase();
});

Vue.filter("uppercase", function (value) {
  return value.toUpperCase();
});

Vue.filter("capitalize", function (value) {
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("titlecase", function (value) {
  return value.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
});

Vue.filter("roundToTwoDecimalPlaces", function (value) {
  return parseFloat(value).toFixed(2);
});

Vue.filter("roundToThreeDecimalPlaces", function (value) {
  return parseFloat(value).toFixed(3);
});

Vue.filter("nameInitials", function (value) {
  const initials = value
    .split(" ")
    .filter((word) => word.length > 1)
    .map((word) => word.charAt(0))
    .join("");

  return initials.slice(0, 3);
});

Vue.filter("stripHtmlTags", function (value) {
  let regexPattern = /(<([^>]+)>)/gi;
  let specialRegexPattern = /(&rsquo;)/gi;
  let plainText = value
    .replace(regexPattern, "")
    .replace(specialRegexPattern, "'");
  return plainText;
});
