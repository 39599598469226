import axios from "axios";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST,
  headers: { "X-COMPANY-UUID": localStorage.getItem("COMPANY-UUID") },
});

const urlList = {
  urlRoot: "/api/v1/settings/crm/",
};

export default {
  getExternalAPIsSettings() {
    let url = urlList.urlRoot + "external-apis/get/";
    return apiClient.get(url);
  },

  updateExternalAPIsSettings(formData) {
    let url = urlList.urlRoot + "external-apis/update/";
    return apiClient.post(url, formData);
  },

  retrieveCompanies() {
    let url = urlList.urlRoot + "sms-provider/" + "companies/";
    return apiClient.get(url);
  },

  getSmsProviderSettings(perPage, page, searchQuery) {
    let url =
      urlList.urlRoot + "sms-provider/?page_size=" + perPage + "&page=" + page;

    if (searchQuery) {
      url += "&search=" + searchQuery;
    }

    return apiClient.get(url);
  },

  retrieveSmsProviderSettings(settingsUUID) {
    let url = urlList.urlRoot + "sms-provider/" + `${settingsUUID}/`;
    return apiClient.get(url);
  },

  createSmsProviderSettings(formData) {
    let url = urlList.urlRoot + "sms-provider/";
    return apiClient.post(url, formData);
  },

  updateSmsProviderSettings(formData, settingsUUID) {
    let url = urlList.urlRoot + "sms-provider/" + `${settingsUUID}/`;
    return apiClient.put(url, formData);
  },

  getSmsProviderSettingsFormData(companyUUID) {
    let url =
      urlList.urlRoot +
      "sms-provider/" +
      "form-data" +
      `?company_uuid=${companyUUID}`;
    return apiClient.get(url);
  },
};
