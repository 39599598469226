import ServiceContacts from "../../service/contacts/ServiceContacts.js";
import { Notification } from "element-ui";

export const namespaced = true;

export const state = {
  customerInfo: {},
  customersList: [],
  customersCount: null,

  customerDocumentsList: [],
  customerCount: null,

  xeroCustomerTaskId: null,
};

export const mutations = {
  SET_CUSTOMER_INFO(state, data) {
    state.customerInfo = data;
  },

  SET_CUSTOMERS_LIST(state, data) {
    state.customersList = data;
  },

  SET_CUSTOMERS_COUNT(state, count) {
    state.customersCount = count;
  },

  ADD_CUSTOMER(state, customer) {
    state.customersList.unshift(customer);
  },

  UPDATE_CUSTOMER(state, customer) {
    state.customersList = state.customersList.map((element) => {
      if (element.id === customer.id) {
        element = JSON.parse(JSON.stringify(customer));
      }
      return element;
    });
  },

  SAVE_XERO_CUSTOMER_TASK_ID(state, taskId) {
    state.xeroCustomerTaskId = taskId;
  },

  CLEAR_XERO_CUSTOMER_TASK_ID(state) {
    state.xeroCustomerTaskId = null;
  },

  SET_CUSTOMER_DOCUMENTS_LIST(state, data) {
    state.customerDocumentsList = data;
  },

  SET_CUSTOMER_DOCUMENTS_COUNT(state, count) {
    state.customerDocumentsCount = count;
  },

  ADD_CUSTOMER_DOCUMENT(state, customerDocument) {
    state.customerDocumentsList.unshift(customerDocument);
  },

  UPDATE_CUSTOMER_DOCUMENT(state, customerDocument) {
    state.customerDocumentsList = state.customerDocumentsList.map((element) => {
      if (element.id === customerDocument.id) {
        element = JSON.parse(JSON.stringify(customerDocument));
      }
      return element;
    });
  },

  DELETE_CUSTOMER_DOCUMENT(state, customerDocumentUUID) {
    state.customerDocumentsList = state.customerDocumentsList.filter(
      (element) => {
        if (element.document_uuid !== customerDocumentUUID) {
          return element;
        }
      }
    );
  },
};

export const actions = {
  getCustomersList(
    { commit },
    { perPage, page, searchQuery, universalSearch }
  ) {
    return ServiceContacts.getCustomersList(perPage, page, searchQuery)
      .then((resp) => {
        if (universalSearch !== true) {
          commit("SET_CUSTOMERS_LIST", resp.data.results);
          commit("SET_CUSTOMERS_COUNT", resp.data.count);
        }

        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getCustomersListWithoutPagination({}, searchQuery) {
    return ServiceContacts.getCustomersListWithoutPagination(searchQuery)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  addCustomer({ commit }, formData) {
    return ServiceContacts.addCustomer(formData)
      .then((resp) => {
        commit("ADD_CUSTOMER", resp.data.customer_data);
        commit("SAVE_XERO_CUSTOMER_TASK_ID", resp.data.xero_customer_task_id);
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Customer added successfully",
        });
        return resp;
      })
      .catch((err) => {
        // Todo: update to show error message for new fields
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.first_name) {
            message = "First Name: " + err.response.data.first_name;
          } else if (err.response.data.last_name) {
            message = "Last Name: " + err.response.data.last_name;
          } else if (err.response.data.email) {
            message = "Email: " + err.response.data.email;
          } else if (err.response.data.phone_number) {
            message = "Phone Number: " + err.response.data.phone_number;
          } else if (err.response.data.street_number) {
            message = "Street No.: " + err.response.data.street_number;
          } else if (err.response.data.street_name) {
            message = "Street Name: " + err.response.data.street_name;
          } else if (err.response.data.city) {
            message = "City: " + err.response.data.city;
          } else if (err.response.data.region) {
            message = "Region: " + err.response.data.region;
          } else if (err.response.data.post_code) {
            message = "Post Code: " + err.response.data.post_code;
          } else if (err.response.data.country) {
            message = "Country: " + err.response.data.country;
          } else if (err.response.data.dob) {
            message = "Date of Birth: " + err.response.data.dob;
          } else if (err.response.data.licence_number) {
            message = "Licence Number: " + err.response.data.licence_number;
          } else if (err.response.data.licence_version) {
            message = "Licence Version: " + err.response.data.licence_version;
          } else if (err.response.data.due_date) {
            message = "Due Date: " + err.response.data.due_date;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  updateCustomer({ commit }, { customerUUID, formData }) {
    return ServiceContacts.updateCustomer(customerUUID, formData)
      .then((resp) => {
        commit("UPDATE_CUSTOMER", resp.data.customer_data);
        commit("SAVE_XERO_CUSTOMER_TASK_ID", resp.data.xero_customer_task_id);
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Customer updated successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.first_name) {
            message = "First Name: " + err.response.data.first_name;
          } else if (err.response.data.last_name) {
            message = "Last Name: " + err.response.data.last_name;
          } else if (err.response.data.email) {
            message = "Email: " + err.response.data.email;
          } else if (err.response.data.phone_number) {
            message = "Phone Number: " + err.response.data.phone_number;
          } else if (err.response.data.street_number) {
            message = "Street No.: " + err.response.data.street_number;
          } else if (err.response.data.street_name) {
            message = "Street Name: " + err.response.data.street_name;
          } else if (err.response.data.city) {
            message = "City: " + err.response.data.city;
          } else if (err.response.data.region) {
            message = "Region: " + err.response.data.region;
          } else if (err.response.data.post_code) {
            message = "Post Code: " + err.response.data.post_code;
          } else if (err.response.data.country) {
            message = "Country: " + err.response.data.country;
          } else if (err.response.data.dob) {
            message = "Date of Birth: " + err.response.data.dob;
          } else if (err.response.data.licence_number) {
            message = "Licence Number: " + err.response.data.licence_number;
          } else if (err.response.data.licence_version) {
            message = "Licence Version: " + err.response.data.licence_version;
          } else if (err.response.data.due_date) {
            message = "Due Date: " + err.response.data.due_date;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  retrieveCustomer({}, customerUUID) {
    return ServiceContacts.retrieveCustomer(customerUUID)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  fetchAndCreateXeroCustomers({}) {
    return ServiceContacts.fetchAndCreateXeroCustomers()
      .then((resp) => {
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Fetched customers from Xero successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          if (err.response.data.message) {
            let message = err.response.data.message;
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Fetching customers from Xero failed",
          });
        }
        return err.response;
      });
  },

  syncCustomerInXero({}, customerUUID) {
    return ServiceContacts.syncCustomerInXero(customerUUID)
      .then((resp) => {
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Synced customer in Xero successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          if (err.response.data.message) {
            let message = err.response.data.message;
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          } else {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: "Failed to sync customer in Xero",
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Failed to sync customer in Xero",
          });
        }
        return err.response;
      });
  },

  checkXeroCustomerTaskStatus({ commit }, formData) {
    return ServiceContacts.checkXeroCustomerTaskStatus(formData)
      .then((resp) => {
        if (["success", "failed"].includes(resp.data.task_status)) {
          commit("CLEAR_XERO_CUSTOMER_TASK_ID");
        }
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  checkCustomerEmailDuplicate({}, formData) {
    return ServiceContacts.checkCustomerEmailDuplicate(formData)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  disableCustomerAllNotifications({}, { customerUUID, formData }) {
    return ServiceContacts.disableCustomerAllNotifications(
      customerUUID,
      formData
    )
      .then((resp) => {
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Customer's all notifications turned off successfully",
        });
        return resp;
      })
      .catch((err) => {
        Notification.error({
          position: "bottom-right",
          duration: 2000,
          title: "Error",
          message: "Oops! Something went wrong",
        });
        return err.response;
      });
  },

  getCustomerFormData({}) {
    return ServiceContacts.getCustomerFormData()
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getCustomerYearSpend({}, customerUUID) {
    return ServiceContacts.getCustomerYearSpend(customerUUID)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  getCustomerDocumentsList({ commit }, { customerUUID, perPage, page }) {
    return ServiceContacts.getCustomerDocumentsList(customerUUID, perPage, page)
      .then((resp) => {
        commit("SET_CUSTOMER_DOCUMENTS_LIST", resp.data.results);
        commit("SET_CUSTOMER_DOCUMENTS_COUNT", resp.data.count);
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },

  addCustomerDocument({ commit }, { customerUUID, formData }) {
    return ServiceContacts.addCustomerDocument(customerUUID, formData)
      .then((resp) => {
        commit("ADD_CUSTOMER_DOCUMENT", resp.data);
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Customer Document uploaded successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.file) {
            message = err.response.data.file;
          } else if (err.response.data.description) {
            message = "Description: " + err.response.data.description;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  updateCustomerDocument(
    { commit },
    { customerUUID, customerDocumentUUID, formData }
  ) {
    return ServiceContacts.updateCustomerDocument(
      customerUUID,
      customerDocumentUUID,
      formData
    )
      .then((resp) => {
        commit("UPDATE_CUSTOMER_DOCUMENT", resp.data);
        Notification.success({
          position: "bottom-right",
          duration: 2000,
          title: "Success",
          message: "Customer Document updated successfully",
        });
        return resp;
      })
      .catch((err) => {
        if (err.response.status == 400) {
          let message = "";

          if (err.response.data.file) {
            message = err.response.data.file;
          } else if (err.response.data.description) {
            message = "Description: " + err.response.data.description;
          } else if (err.response.data.message) {
            message = err.response.data.message;
          } else if (err.response.data.non_field_errors) {
            message = err.response.data.non_field_errors[0];
          }

          if (message) {
            Notification.error({
              position: "bottom-right",
              duration: 2000,
              title: "Error",
              message: message,
            });
          }
        } else {
          Notification.error({
            position: "bottom-right",
            duration: 2000,
            title: "Error",
            message: "Oops! Something went wrong",
          });
        }

        return err.response;
      });
  },

  deleteCustomerDocument({ commit }, { customerUUID, customerDocumentUUID }) {
    return ServiceContacts.deleteCustomerDocument(
      customerUUID,
      customerDocumentUUID
    )
      .then((resp) => {
        commit("DELETE_CUSTOMER_DOCUMENT", customerDocumentUUID);
        Notification.error({
          position: "bottom-right",
          duration: 2000,
          title: "Delete",
          message: "Customer Document deleted successfully",
        });
        return resp;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },
};
