import axios from "axios";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST,
  headers: { "X-COMPANY-UUID": localStorage.getItem("COMPANY-UUID") },
});

const urlList = {
  urlRoot: "/api/v1/inventory/",
};

export default {
  // products
  getProductsListWithoutPagination(
    searchQuery,
    departments,
    brands,
    suppliers,
    productTypes
  ) {
    let url = urlList.urlRoot + "products/?search=" + searchQuery;

    if (departments) {
      url += "&departments=" + departments;
    }
    if (brands) {
      url += "&brands=" + brands;
    }
    if (suppliers) {
      url += "&suppliers=" + suppliers;
    }
    if (productTypes) {
      url += "&product_types=" + productTypes;
    }

    return apiClient.get(url);
  },

  getTyreProductsList(
    perPage,
    page,
    searchQuery,
    departments,
    brands,
    suppliers
  ) {
    let url =
      urlList.urlRoot +
      "products/tyres-list/?page_size=" +
      perPage +
      "&page=" +
      page;

    if (searchQuery) {
      url += "&search=" + searchQuery;
    }
    if (departments) {
      url += "&departments=" + departments;
    }
    if (brands) {
      url += "&brands=" + brands;
    }
    if (suppliers) {
      url += "&suppliers=" + suppliers;
    }

    return apiClient.get(url);
  },

  getWheelProductsList(
    perPage,
    page,
    searchQuery,
    departments,
    brands,
    suppliers
  ) {
    let url =
      urlList.urlRoot +
      "products/wheels-list/?page_size=" +
      perPage +
      "&page=" +
      page;

    if (searchQuery) {
      url += "&search=" + searchQuery;
    }
    if (departments) {
      url += "&departments=" + departments;
    }
    if (brands) {
      url += "&brands=" + brands;
    }
    if (suppliers) {
      url += "&suppliers=" + suppliers;
    }

    return apiClient.get(url);
  },

  getGeneralProductsList(
    perPage,
    page,
    searchQuery,
    departments,
    brands,
    suppliers,
    productTypes
  ) {
    let url =
      urlList.urlRoot +
      "products/general-products-list/?page_size=" +
      perPage +
      "&page=" +
      page;

    if (searchQuery) {
      url += "&search=" + searchQuery;
    }
    if (departments) {
      url += "&departments=" + departments;
    }
    if (brands) {
      url += "&brands=" + brands;
    }
    if (suppliers) {
      url += "&suppliers=" + suppliers;
    }
    if (productTypes) {
      url += "&product_types=" + productTypes;
    }

    return apiClient.get(url);
  },

  getTrackedInventoryProductsList(
    perPage,
    page,
    searchQuery,
    departments,
    brands,
    suppliers,
    productTypes
  ) {
    let url =
      urlList.urlRoot +
      "products/inventory-products-list/?page_size=" +
      perPage +
      "&page=" +
      page;

    if (searchQuery) {
      url += "&search=" + searchQuery;
    }
    if (departments) {
      url += "&departments=" + departments;
    }
    if (brands) {
      url += "&brands=" + brands;
    }
    if (suppliers) {
      url += "&suppliers=" + suppliers;
    }
    if (productTypes) {
      url += "&product_types=" + productTypes;
    }

    return apiClient.get(url);
  },

  getMechanicScreenDisplayProductsListWithoutPagination() {
    let url = urlList.urlRoot + "products/mechanic-screen-display/";
    return apiClient.get(url);
  },

  getVehicleRecommendedProductsListWithoutPagination(vehicleUUID, searchQuery) {
    let url =
      urlList.urlRoot +
      `products/vehicles/${vehicleUUID}/recommended-products/`;

    if (searchQuery) {
      url += "?search=" + searchQuery;
    }

    return apiClient.get(url);
  },

  addProduct(formData) {
    let url = urlList.urlRoot + "products/";
    return apiClient.post(url, formData);
  },

  retrieveProduct(productUUID) {
    let url = urlList.urlRoot + `products/${productUUID}`;
    return apiClient.get(url);
  },

  updateProduct(productUUID, formData) {
    let url = urlList.urlRoot + `products/${productUUID}/`;
    return apiClient.patch(url, formData);
  },

  deleteProduct(productUUID) {
    let url = urlList.urlRoot + `products/${productUUID}/`;
    return apiClient.delete(url);
  },

  getProductFormData() {
    let url = urlList.urlRoot + "products/form-data/";
    return apiClient.get(url);
  },

  // product documents
  getProductDocumentsList(productUUID, perPage, page) {
    let url =
      urlList.urlRoot +
      `products/${productUUID}/documents/?page_size=` +
      perPage +
      "&page=" +
      page;

    return apiClient.get(url);
  },

  addProductDocument(productUUID, formData) {
    let url = urlList.urlRoot + `products/${productUUID}/documents/`;
    return apiClient.post(url, formData);
  },

  updateProductDocument(productUUID, productDocumentUUID, formData) {
    let url =
      urlList.urlRoot +
      `products/${productUUID}/documents/${productDocumentUUID}/`;
    return apiClient.patch(url, formData);
  },

  deleteProductDocument(productUUID, productDocumentUUID) {
    let url =
      urlList.urlRoot +
      `products/${productUUID}/documents/${productDocumentUUID}/`;
    return apiClient.delete(url);
  },

  // brands
  getBrandsList(perPage, page, searchQuery) {
    let url =
      urlList.urlRoot + "brands/?page_size=" + perPage + "&page=" + page;

    if (searchQuery) {
      url += "&search=" + searchQuery;
    }

    return apiClient.get(url);
  },

  addBrand(formData) {
    let url = urlList.urlRoot + "brands/";
    return apiClient.post(url, formData);
  },

  updateBrand(objId, formData) {
    let url = urlList.urlRoot + `brands/${objId}/`;
    return apiClient.patch(url, formData);
  },

  deleteBrand(objId) {
    let url = urlList.urlRoot + `brands/${objId}/`;
    return apiClient.delete(url);
  },

  // suppliers
  getSuppliersList(perPage, page, searchQuery) {
    let url =
      urlList.urlRoot + "suppliers/?page_size=" + perPage + "&page=" + page;

    if (searchQuery) {
      url += "&search=" + searchQuery;
    }

    return apiClient.get(url);
  },

  addSupplier(formData) {
    let url = urlList.urlRoot + "suppliers/";
    return apiClient.post(url, formData);
  },

  updateSupplier(objId, formData) {
    let url = urlList.urlRoot + `suppliers/${objId}/`;
    return apiClient.patch(url, formData);
  },

  deleteSupplier(objId) {
    let url = urlList.urlRoot + `suppliers/${objId}/`;
    return apiClient.delete(url);
  },

  // product types
  getProductTypesList(perPage, page, searchQuery) {
    let url =
      urlList.urlRoot + "product-types/?page_size=" + perPage + "&page=" + page;

    if (searchQuery) {
      url += "&search=" + searchQuery;
    }

    return apiClient.get(url);
  },

  getProductTypesListWithoutPagination() {
    let url = urlList.urlRoot + "product-types/";
    return apiClient.get(url);
  },

  addProductType(formData) {
    let url = urlList.urlRoot + "product-types/";
    return apiClient.post(url, formData);
  },

  updateProductType(objId, formData) {
    let url = urlList.urlRoot + `product-types/${objId}/`;
    return apiClient.patch(url, formData);
  },

  deleteProductType(objId) {
    let url = urlList.urlRoot + `product-types/${objId}/`;
    return apiClient.delete(url);
  },

  // inventory report
  getInventoryStockOnHandReport(
    perPage,
    page,
    searchQuery,
    departments,
    brands,
    suppliers,
    productTypes
  ) {
    let url =
      urlList.urlRoot +
      "reports/stock-on-hand/?page_size=" +
      perPage +
      "&page=" +
      page;

    if (searchQuery) {
      url += "&search=" + searchQuery;
    }
    if (departments) {
      url += "&departments=" + departments;
    }
    if (brands) {
      url += "&brands=" + brands;
    }
    if (suppliers) {
      url += "&suppliers=" + suppliers;
    }
    if (productTypes) {
      url += "&product_types=" + productTypes;
    }

    return apiClient.get(url);
  },

  getInventoryProductPerformanceReport(
    perPage,
    page,
    timePeriod,
    searchQuery,
    departments,
    brands,
    suppliers,
    productTypes
  ) {
    let url =
      urlList.urlRoot +
      "reports/product-performance/?page_size=" +
      perPage +
      "&page=" +
      page +
      "&time_period=" +
      timePeriod;

    if (searchQuery) {
      url += "&search=" + searchQuery;
    }
    if (departments) {
      url += "&departments=" + departments;
    }
    if (brands) {
      url += "&brands=" + brands;
    }
    if (suppliers) {
      url += "&suppliers=" + suppliers;
    }
    if (productTypes) {
      url += "&product_types=" + productTypes;
    }

    return apiClient.get(url);
  },

  getJobsProductPerformance(productUUID, timePeriod) {
    let url =
      urlList.urlRoot +
      "reports/product-performance/" +
      `${productUUID}/jobs/${timePeriod}/`;

    return apiClient.get(url);
  },

  getInventoryLowStockReport(
    perPage,
    page,
    searchQuery,
    departments,
    brands,
    suppliers,
    productTypes
  ) {
    let url =
      urlList.urlRoot +
      "reports/low-stock/?page_size=" +
      perPage +
      "&page=" +
      page;

    if (searchQuery) {
      url += "&search=" + searchQuery;
    }
    if (departments) {
      url += "&departments=" + departments;
    }
    if (brands) {
      url += "&brands=" + brands;
    }
    if (suppliers) {
      url += "&suppliers=" + suppliers;
    }
    if (productTypes) {
      url += "&product_types=" + productTypes;
    }

    return apiClient.get(url);
  },

  generateInventoryLowStockReportPDF(
    perPage,
    page,
    searchQuery,
    departments,
    brands,
    suppliers,
    productTypes
  ) {
    let url =
      urlList.urlRoot +
      "reports/low-stock/generate-pdf/?page_size=" +
      perPage +
      "&page=" +
      page;

    if (searchQuery) {
      url += "&search=" + searchQuery;
    }
    if (departments) {
      url += "&departments=" + departments;
    }
    if (brands) {
      url += "&brands=" + brands;
    }
    if (suppliers) {
      url += "&suppliers=" + suppliers;
    }
    if (productTypes) {
      url += "&product_types=" + productTypes;
    }

    return apiClient.post(url);
  },

  checkInventoryLowStockReportPDFTaskStatus(formData) {
    let url = urlList.urlRoot + "reports/low-stock/pdf-task-status/";
    return apiClient.post(url, formData);
  },

  generateInventoryLowStockReportExcel(
    perPage,
    page,
    searchQuery,
    departments,
    brands,
    suppliers,
    productTypes
  ) {
    let url =
      urlList.urlRoot +
      "reports/low-stock/generate-excel/?page_size=" +
      perPage +
      "&page=" +
      page;

    if (searchQuery) {
      url += "&search=" + searchQuery;
    }
    if (departments) {
      url += "&departments=" + departments;
    }
    if (brands) {
      url += "&brands=" + brands;
    }
    if (suppliers) {
      url += "&suppliers=" + suppliers;
    }
    if (productTypes) {
      url += "&product_types=" + productTypes;
    }

    return apiClient.post(url);
  },

  checkInventoryLowStockReportExcelTaskStatus(formData) {
    let url = urlList.urlRoot + "reports/low-stock/excel-task-status/";
    return apiClient.post(url, formData);
  },
};
