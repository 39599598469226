import Vue from "vue";
import Router from "vue-router";

// dashboard
const PageDashboard = () => import("../pages/dashboard/PageDashboard.vue");
const PageJobsRevenue = () => import("../pages/dashboard/PageJobsRevenue.vue");

// user
const PageChangePassword = () => import("../pages/auth/PageChangePassword.vue");
const PageEnforcedChangePassword = () =>
  import("../pages/auth/PageEnforcedChangePassword.vue");
const PageLogin = () => import("../pages/auth/PageLogin.vue");
const PageOtpLogin = () => import("../pages/auth/PageOtpLogin.vue");
const PageOtpSignup = () => import("../pages/auth/PageOtpSignup.vue");
const PageSignup = () => import("../pages/auth/PageSignup.vue");
const PageUserInvitationHandler = () =>
  import("../pages/auth/PageUserInvitationHandler.vue");
const PageUserHome = () => import("../pages/user/PageUserHome.vue");
const PageEditUser = () => import("../pages/user/PageEditUser.vue");
const PageUsers = () => import("../pages/user/PageUsers.vue");
const PageUserInvitations = () =>
  import("../pages/user/PageUserInvitations.vue");
const PageUserProfile = () => import("../pages/user/PageUserProfile.vue");
const PagePasswordReset = () =>
  import("../pages/auth/password_reset/PagePasswordReset.vue");
const PagePasswordResetDone = () =>
  import("../pages/auth/password_reset/PagePasswordResetDone.vue");
const PagePasswordResetConfirm = () =>
  import("../pages/auth/password_reset/PagePasswordResetConfirm.vue");
const PagePasswordResetComplete = () =>
  import("../pages/auth/password_reset/PagePasswordResetComplete.vue");

// vehicles
const PageWorkshopVehicles = () =>
  import("../pages/workshop/vehicle/PageWorkshopVehicles.vue");
const PageAddWorkshopVehicle = () =>
  import("../pages/workshop/vehicle/PageAddWorkshopVehicle.vue");
const PageEditWorkshopVehicle = () =>
  import("../pages/workshop/vehicle/PageEditWorkshopVehicle.vue");
const PageWorkshopVehicleDetails = () =>
  import("../pages/workshop/vehicle/PageWorkshopVehicleDetails.vue");

// job kit
const PageJobKitCategories = () =>
  import("../pages/workshop/job_kit/PageJobKitCategories.vue");
const PageJobKits = () => import("../pages/workshop/job_kit/PageJobKits.vue");
const PageAddJobKit = () =>
  import("../pages/workshop/job_kit/PageAddJobKit.vue");
const PageEditJobKit = () =>
  import("../pages/workshop/job_kit/PageEditJobKit.vue");
const PageJobKitDetails = () =>
  import("../pages/workshop/job_kit/PageJobKitDetails.vue");

// job
const PageJobs = () => import("../pages/workshop/job/PageJobs.vue");
const PageAddJob = () => import("../pages/workshop/job/PageAddJob.vue");
const PageEditJob = () => import("../pages/workshop/job/PageEditJob.vue");
const PageJobDetails = () => import("../pages/workshop/job/PageJobDetails.vue");
const PageWorkshopJobsCalendar = () =>
  import("../pages/workshop/job/PageWorkshopJobsCalendar.vue");
const PageMechanicDayJobsCalendar = () =>
  import("../pages/workshop/job/PageMechanicDayJobsCalendar.vue");
const PageMechanicJobScreen = () =>
  import("../pages/workshop/job/PageMechanicJobScreen.vue");
const PageJobQuickPhotosUpload = () =>
  import("../pages/workshop/job/PageJobQuickPhotosUpload.vue");
const PageCustomerJobServiceReport = () =>
  import("../pages/workshop/job/PageCustomerJobServiceReport.vue");

// quick job
const PageAddQuickJob = () =>
  import("../pages/workshop/quick_job/PageAddQuickJob.vue");
const PageEditQuickJob = () =>
  import("../pages/workshop/quick_job/PageEditQuickJob.vue");
const PageQuickJobDetails = () =>
  import("../pages/workshop/quick_job/PageQuickJobDetails.vue");

// quote
const PageQuotes = () => import("../pages/workshop/quote/PageQuotes.vue");
const PageCreateQuote = () =>
  import("../pages/workshop/quote/PageCreateQuote.vue");
const PageEditQuote = () => import("../pages/workshop/quote/PageEditQuote.vue");
const PageQuoteDetails = () =>
  import("../pages/workshop/quote/PageQuoteDetails.vue");
const PageCustomerQuoteDetails = () =>
  import("../pages/workshop/quote/PageCustomerQuoteDetails.vue");

// invoice
const PageWorkshopInvoice = () =>
  import("../pages/workshop/invoice/PageWorkshopInvoice.vue");
const PageEditWorkshopInvoice = () =>
  import("../pages/workshop/invoice/PageEditWorkshopInvoice.vue");
const PageCustomerInvoiceDetails = () =>
  import("../pages/workshop/invoice/PageCustomerInvoiceDetails.vue");

// general service
const PageGeneralServices = () =>
  import("../pages/workshop/general_service/PageGeneralServices.vue");
const PageAddGeneralService = () =>
  import("../pages/workshop/general_service/PageAddGeneralService.vue");
const PageEditGeneralService = () =>
  import("../pages/workshop/general_service/PageEditGeneralService.vue");
const PageGeneralServiceDetails = () =>
  import("../pages/workshop/general_service/PageGeneralServiceDetails.vue");

// customer
const PageCustomers = () => import("../pages/contacts/PageCustomers.vue");
const PageCustomerDetails = () =>
  import("../pages/contacts/PageCustomerDetails.vue");
const PageAddCustomer = () => import("../pages/contacts/PageAddCustomer.vue");
const PageEditCustomer = () => import("../pages/contacts/PageEditCustomer.vue");
const PageAddCustomerEmail = () =>
  import("../pages/contacts/PageAddCustomerEmail.vue");

// inventory
const PageBrands = () => import("../pages/inventory/items/PageBrands.vue");
const PageSuppliers = () =>
  import("../pages/inventory/items/PageSuppliers.vue");
const PageProductTypes = () =>
  import("../pages/inventory/items/PageProductTypes.vue");

const PageGeneralProducts = () =>
  import("../pages/inventory/product/general/PageGeneralProducts.vue");
const PageAddGeneralProduct = () =>
  import("../pages/inventory/product/general/PageAddGeneralProduct.vue");
const PageEditGeneralProduct = () =>
  import("../pages/inventory/product/general/PageEditGeneralProduct.vue");

const PageTyreProducts = () =>
  import("../pages/inventory/product/tyre/PageTyreProducts.vue");
const PageQuickAddTyreProduct = () =>
  import("../pages/inventory/product/tyre/PageQuickAddTyreProduct.vue");
const PageAddTyreProduct = () =>
  import("../pages/inventory/product/tyre/PageAddTyreProduct.vue");
const PageEditTyreProduct = () =>
  import("../pages/inventory/product/tyre/PageEditTyreProduct.vue");

const PageWheelProducts = () =>
  import("../pages/inventory/product/wheel/PageWheelProducts.vue");
const PageAddWheelProduct = () =>
  import("../pages/inventory/product/wheel/PageAddWheelProduct.vue");
const PageEditWheelProduct = () =>
  import("../pages/inventory/product/wheel/PageEditWheelProduct.vue");

const PageProductDetails = () =>
  import("../pages/inventory/product/PageProductDetails.vue");
const PageProductsQuickStockUpdate = () =>
  import("../pages/inventory/product/PageProductsQuickStockUpdate.vue");

// reports
const PageInventoryStockOnHandReport = () =>
  import("../pages/inventory/reports/PageInventoryStockOnHandReport.vue");
const PageInventoryProductPerformanceReport = () =>
  import(
    "../pages/inventory/reports/PageInventoryProductPerformanceReport.vue"
  );
const PageInventoryLowStockReport = () =>
  import("../pages/inventory/reports/PageInventoryLowStockReport.vue");
const PageInventoryJobsProductPerformanceReport = () =>
  import(
    "../pages/inventory/reports/PageInventoryJobsProductPerformanceReport.vue"
  );
const PageVehiclesWofReport = () =>
  import("../pages/reports/vehicles/PageVehiclesWofReport.vue");
const PageVehiclesServiceReminderReport = () =>
  import("../pages/reports/vehicles/PageVehiclesServiceReminderReport.vue");
const PageHistoricalOpenJobsReport = () =>
  import("../pages/reports/jobs/PageHistoricalOpenJobsReport.vue");
const PageWofConflictOfInterestReport = () =>
  import("../pages/reports/vehicles/PageWofConflictOfInterestReport.vue");

// settings
const PageSettingsAdminCompany = () =>
  import("../pages/settings/admin/PageSettingsAdminCompany.vue");
const PageSettingsAdminJob = () =>
  import("../pages/settings/admin/PageSettingsAdminJob.vue");
const PageSettingsAdminInvoice = () =>
  import("../pages/settings/admin/PageSettingsAdminInvoice.vue");
const PageSettingsAdminCustomer = () =>
  import("../pages/settings/admin/PageSettingsAdminCustomer.vue");
const PageSettingsAdminNotification = () =>
  import("../pages/settings/admin/PageSettingsAdminNotification.vue");
const PageSettingsAdminXero = () =>
  import("../pages/settings/admin/PageSettingsAdminXero.vue");

// crm authority
const PageCrmSettingsExternalApis = () =>
  import("../pages/settings/crm_authority/PageCrmSettingsExternalApis.vue");
const PageAddCrmSettingsSmsProivder = () =>
  import("../pages/settings/crm_authority/PageAddCrmSettingsSmsProivder.vue");
const PageEditCrmSettingsSmsProvider = () =>
  import("../pages/settings/crm_authority/PageEditCrmSettingsSmsProvider.vue");
const PageViewCrmSettingsSmsProvider = () =>
  import("../pages/settings/crm_authority/PageViewCrmSettingsSmsProvider.vue");
const PageCrmSettingsSmsProvider = () =>
  import("../pages/settings/crm_authority/PageCrmSettingsSmsProvider.vue");

Vue.use(Router);

const routes = [
  {
    path: "/account/password_reset",
    name: "password-reset",
    component: PagePasswordReset,
    props: true,
    meta: {
      title: "Password Reset",
    },
  },
  {
    path: "/account/password_reset/done/",
    name: "password-reset-done",
    component: PagePasswordResetDone,
    meta: {
      title: "Password Reset Done",
    },
  },
  {
    path: "/account/reset/done/",
    name: "password-reset-complete",
    component: PagePasswordResetComplete,
    meta: {
      title: "Password Reset Complete",
    },
  },
  {
    path: "/account/reset/:token/confirm",
    name: "password-reset-confirm",
    component: PagePasswordResetConfirm,
    props: true,
    meta: {
      title: "Password Reset Confirm",
    },
  },
  {
    path: "/login/",
    name: "login",
    component: PageLogin,
    meta: { title: "Login" },
  },
  {
    path: "/login/:token/verify-otp/",
    name: "login-verify-otp",
    component: PageOtpLogin,
    meta: { title: "Verify OTP" },
  },
  {
    path: "/signup/:token/verify-otp/",
    name: "signup-verify-otp",
    component: PageOtpSignup,
    meta: { title: "Verify OTP" },
  },
  {
    path: "/user-invitation/:invitation_uuid/:token/",
    name: "user-invitation",
    component: PageUserInvitationHandler,
    meta: { title: "User Invitation" },
  },
  {
    path: "/:company_handle/dashboard/",
    name: "dashboard",
    component: PageDashboard,
    meta: { title: "Dashboard" },
  },
  {
    path: "/:company_handle/dashboard/product-types/:product_type_slug/jobs-revenue/:time_period/",
    name: "product-type-jobs-revenue",
    component: PageJobsRevenue,
    meta: { title: "Jobs Revenue By Product Type" },
  },
  {
    path: "/home/",
    name: "home",
    component: PageUserHome,
    meta: { title: "Home" },
  },
  {
    path: "/:company_handle/users/:company_user_uuid/edit/",
    name: "edit-user",
    component: PageEditUser,
    meta: { title: "Edit User" },
  },
  {
    path: "/:company_handle/users/",
    name: "users",
    component: PageUsers,
    meta: { title: "Users" },
  },
  {
    path: "/:company_handle/users/invitations/",
    name: "user-invitations",
    component: PageUserInvitations,
    meta: { title: "User Invitations" },
  },
  {
    path: "/profile/",
    name: "profile",
    component: PageUserProfile,
    meta: { title: "Profile" },
  },
  {
    path: "/change-password/",
    name: "change-password",
    component: PageChangePassword,
    meta: { title: "Change Password" },
  },
  {
    path: "/change-password/enforced/",
    name: "enforced-change-password",
    component: PageEnforcedChangePassword,
    meta: { title: "Change Password" },
  },
  {
    path: "/:company_handle/workshop/calendar/",
    name: "workshop-jobs-calendar",
    component: PageWorkshopJobsCalendar,
    meta: { title: "Jobs Calendar" },
  },
  {
    path: "/:company_handle/workshop/calendar/mechanic/",
    name: "workshop-mechanic-calendar",
    component: PageMechanicDayJobsCalendar,
    meta: { title: "Mechanic Jobs Calendar" },
  },
  {
    path: "/:company_handle/workshop/vehicles/",
    name: "workshop-vehicles",
    component: PageWorkshopVehicles,
    meta: { title: "Workshop Vehicles" },
  },
  {
    path: "/:company_handle/workshop/vehicles/add/",
    name: "add-workshop-vehicle",
    component: PageAddWorkshopVehicle,
    meta: { title: "Add Workshop Vehicle" },
  },
  {
    path: "/:company_handle/workshop/vehicles/:vehicle_uuid/edit/",
    name: "edit-workshop-vehicle",
    component: PageEditWorkshopVehicle,
    meta: { title: "Edit Workshop Vehicle" },
  },
  {
    path: "/:company_handle/workshop/vehicles/:vehicle_uuid/",
    name: "workshop-vehicle-details",
    component: PageWorkshopVehicleDetails,
    meta: { title: "Workshop Vehicle Details" },
  },
  {
    path: "/:company_handle/workshop/invoices/:invoice_uuid/",
    name: "workshop-job-invoice-preview",
    component: PageWorkshopInvoice,
    meta: { title: "Workshop Job Invoice" },
  },
  {
    path: "/:company_handle/workshop/invoices/:invoice_uuid/edit/",
    name: "workshop-job-invoice-edit",
    component: PageEditWorkshopInvoice,
    meta: { title: "Edit Workshop Job Invoice" },
  },
  {
    path: "/:company_handle/workshop/cash-sales/add/",
    name: "add-workshop-cash-sale",
    component: PageAddQuickJob,
    meta: { title: "Add Cash Sale" },
  },
  {
    path: "/:company_handle/workshop/cash-sales/invoices/:invoice_uuid/",
    name: "workshop-cash-sale-details",
    component: PageQuickJobDetails,
    meta: { title: "Cash Sale Details" },
  },
  {
    path: "/:company_handle/workshop/cash-sales/invoices/:invoice_uuid/edit/",
    name: "edit-workshop-cash-sale",
    component: PageEditQuickJob,
    meta: { title: "Edit Cash Sale" },
  },
  {
    path: "/workshop/invoices/:base64_company_uuid/:invoice_uuid/customer-view/",
    name: "workshop-invoice-customer-view",
    component: PageCustomerInvoiceDetails,
    props: true,
    meta: { title: "Invoice" },
  },
  {
    path: "/:company_handle/workshop/jobs/",
    name: "workshop-jobs",
    component: PageJobs,
    meta: { title: "Jobs" },
  },
  {
    path: "/:company_handle/workshop/jobs/add/",
    name: "add-workshop-job",
    component: PageAddJob,
    meta: { title: "Add Job" },
  },
  {
    path: "/:company_handle/workshop/jobs/:job_uuid/edit/",
    name: "edit-workshop-job",
    component: PageEditJob,
    meta: { title: "Edit Job" },
  },
  {
    path: "/:company_handle/workshop/jobs/:job_uuid/mechanic-screen/",
    name: "workshop-job-mechanic-screen",
    component: PageMechanicJobScreen,
    meta: { title: "Mechanic Screen" },
  },
  {
    path: "/:company_handle/workshop/jobs/:job_uuid/",
    name: "workshop-job-details",
    component: PageJobDetails,
    meta: { title: "Job Details" },
  },
  {
    path: "/workshop/jobs/:job_uuid/service-report/customer-view/",
    name: "workshop-job-service-report-customer-view",
    component: PageCustomerJobServiceReport,
    meta: { title: "Job Service Report" },
  },
  {
    path: "/workshop/jobs/:base64_token/quick-photos-upload/",
    name: "workshop-job-quick-photos-upload",
    component: PageJobQuickPhotosUpload,
    meta: { title: "Upload Job Photos" },
  },
  {
    path: "/:company_handle/workshop/job-kits/",
    name: "workshop-job-kits",
    component: PageJobKits,
    meta: { title: "Job Kits" },
  },
  {
    path: "/:company_handle/workshop/job-kits/add/",
    name: "add-workshop-job-kit",
    component: PageAddJobKit,
    meta: { title: "Add Job Kit" },
  },
  {
    path: "/:company_handle/workshop/job-kits/:jobkit_uuid/edit/",
    name: "edit-workshop-job-kit",
    component: PageEditJobKit,
    meta: { title: "Edit Job Kit" },
  },
  {
    path: "/:company_handle/workshop/job-kits/:jobkit_uuid/",
    name: "workshop-job-kit-details",
    component: PageJobKitDetails,
    meta: { title: "Job Kit Details" },
  },
  {
    path: "/:company_handle/workshop/job-kit-categories/",
    name: "workshop-job-kit-categories",
    component: PageJobKitCategories,
    meta: { title: "Job Kit Categories" },
  },
  {
    path: "/:company_handle/workshop/quotes/",
    name: "workshop-quotes",
    component: PageQuotes,
    meta: { title: "Quotes" },
  },
  {
    path: "/:company_handle/workshop/quotes/create/",
    name: "create-workshop-quote",
    component: PageCreateQuote,
    meta: { title: "New Quote" },
  },
  {
    path: "/:company_handle/workshop/quotes/:quote_uuid/edit/",
    name: "edit-workshop-quote",
    component: PageEditQuote,
    meta: { title: "Edit Quote" },
  },
  {
    path: "/:company_handle/workshop/quotes/:quote_uuid/",
    name: "workshop-quote-details",
    component: PageQuoteDetails,
    meta: { title: "Quote" },
  },
  {
    path: "/workshop/quotes/:quote_uuid/customer-view/",
    name: "workshop-quote-customer-view",
    component: PageCustomerQuoteDetails,
    props: true,
    meta: { title: "Quote" },
  },
  {
    path: "/:company_handle/workshop/general-services/",
    name: "workshop-general-services",
    component: PageGeneralServices,
    meta: { title: "General Services" },
  },
  {
    path: "/:company_handle/workshop/general-services/add/",
    name: "add-workshop-general-service",
    component: PageAddGeneralService,
    meta: { title: "Add General Service" },
  },
  {
    path: "/:company_handle/workshop/general-services/:service_uuid/edit/",
    name: "edit-workshop-general-service",
    component: PageEditGeneralService,
    meta: { title: "Edit General Service" },
  },
  {
    path: "/:company_handle/workshop/general-services/:service_uuid/",
    name: "workshop-general-service-details",
    component: PageGeneralServiceDetails,
    meta: { title: "General Service Details" },
  },
  {
    path: "/:company_handle/customers/",
    name: "customers-list",
    component: PageCustomers,
    meta: { title: "Customers" },
  },
  {
    path: "/:company_handle/customers/add/",
    name: "add-customer",
    component: PageAddCustomer,
    meta: { title: "Add Customer" },
  },
  {
    path: "/:company_handle/customers/:customer_uuid/edit/",
    name: "edit-customer",
    component: PageEditCustomer,
    meta: { title: "Edit Customer" },
  },
  {
    path: "/:company_handle/customers/:customer_uuid/add-email/",
    name: "add-customer-email",
    component: PageAddCustomerEmail,
    meta: { title: "Add Customer Email" },
  },
  {
    path: "/:company_handle/customers/:customer_uuid/",
    name: "customer-details",
    component: PageCustomerDetails,
    meta: { title: "Customer Details" },
  },
  {
    path: "/:company_handle/products/general/",
    name: "general-products-list",
    component: PageGeneralProducts,
    meta: { title: "General Products" },
  },
  {
    path: "/:company_handle/products/tyre/",
    name: "tyre-products-list",
    component: PageTyreProducts,
    meta: { title: "Tyres" },
  },
  {
    path: "/:company_handle/products/wheel/",
    name: "wheel-products-list",
    component: PageWheelProducts,
    meta: { title: "Wheels" },
  },
  {
    path: "/:company_handle/products/add-tyre/",
    name: "add-tyre-product",
    component: PageAddTyreProduct,
    meta: { title: "Add Tyre" },
  },
  {
    path: "/:company_handle/products/quick-add-tyre/",
    name: "quick-add-tyre-product",
    component: PageQuickAddTyreProduct,
    meta: { title: "Quick Add Tyre" },
  },
  {
    path: "/:company_handle/products/add-wheel/",
    name: "add-wheel-product",
    component: PageAddWheelProduct,
    meta: { title: "Add Wheel" },
  },
  {
    path: "/:company_handle/products/add-general/",
    name: "add-general-product",
    component: PageAddGeneralProduct,
    meta: { title: "Add General Product" },
  },
  {
    path: "/:company_handle/products/tyre/:product_uuid/edit/",
    name: "edit-tyre-product",
    component: PageEditTyreProduct,
    meta: { title: "Edit Tyre" },
  },
  {
    path: "/:company_handle/products/wheel/:product_uuid/edit/",
    name: "edit-wheel-product",
    component: PageEditWheelProduct,
    meta: { title: "Edit Wheel" },
  },
  {
    path: "/:company_handle/products/general/:product_uuid/edit/",
    name: "edit-general-product",
    component: PageEditGeneralProduct,
    meta: { title: "Edit General Product" },
  },
  {
    path: "/:company_handle/products/:product_uuid/",
    name: "product-details",
    component: PageProductDetails,
    meta: { title: "Product Details" },
  },
  {
    path: "/:company_handle/products/stock/quick-update/",
    name: "products-quick-stock-update",
    component: PageProductsQuickStockUpdate,
    meta: { title: "Products - Quick Stock Update" },
  },
  {
    path: "/:company_handle/inventory/brands/",
    name: "inventory-brands",
    component: PageBrands,
    meta: { title: "Brands" },
  },
  {
    path: "/:company_handle/inventory/suppliers/",
    name: "inventory-suppliers",
    component: PageSuppliers,
    meta: { title: "Suppliers" },
  },
  {
    path: "/:company_handle/inventory/product-types/",
    name: "inventory-product-types",
    component: PageProductTypes,
    meta: { title: "Product Types" },
  },
  {
    path: "/:company_handle/reports/inventory/stock-on-hand/",
    name: "report-inventory-stock-on-hand",
    component: PageInventoryStockOnHandReport,
    meta: { title: "Report - Stock On Hand" },
  },
  {
    path: "/:company_handle/reports/inventory/product-performance/",
    name: "report-inventory-product-performance",
    component: PageInventoryProductPerformanceReport,
    meta: { title: "Report - Product Performance" },
  },
  {
    path: "/:company_handle/reports/inventory/product-performance/:product_uuid/jobs/:time_period/",
    name: "report-inventory-jobs-product-performance",
    component: PageInventoryJobsProductPerformanceReport,
    meta: { title: "Report - Jobs Product Performance" },
  },
  {
    path: "/:company_handle/reports/inventory/low-stock/",
    name: "report-inventory-low-stock",
    component: PageInventoryLowStockReport,
    meta: { title: "Report - Low Stock" },
  },
  {
    path: "/:company_handle/reports/vehicles/wof/",
    name: "report-vehicles-wof",
    component: PageVehiclesWofReport,
    meta: { title: "WOF Report" },
  },
  {
    path: "/:company_handle/reports/vehicles/service-reminders/",
    name: "report-vehicles-service-reminder",
    component: PageVehiclesServiceReminderReport,
    meta: { title: "Service Reminder Report" },
  },
  {
    path: "/:company_handle/reports/vehicles/wof-conflict-of-interest/",
    name: "report-vehicles-wof-conflict-of-interest",
    component: PageWofConflictOfInterestReport,
    meta: { title: "WOF Conflict of Interest Register" },
  },
  {
    path: "/:company_handle/reports/jobs/historical-open/",
    name: "report-jobs-historical-open",
    component: PageHistoricalOpenJobsReport,
    meta: { title: "Historical Open Jobs" },
  },
  {
    path: "/:company_handle/settings/admin/company/",
    name: "settings-admin-company",
    component: PageSettingsAdminCompany,
    meta: { title: "Company Details" },
  },
  {
    path: "/:company_handle/settings/admin/job/",
    name: "settings-admin-job",
    component: PageSettingsAdminJob,
    meta: { title: "Job Settings" },
  },
  {
    path: "/:company_handle/settings/admin/invoice/",
    name: "settings-admin-invoice",
    component: PageSettingsAdminInvoice,
    meta: { title: "Invoice Settings" },
  },
  {
    path: "/:company_handle/settings/admin/customer/",
    name: "settings-admin-customer",
    component: PageSettingsAdminCustomer,
    meta: { title: "Customer Settings" },
  },
  {
    path: "/:company_handle/settings/admin/notification/",
    name: "settings-admin-notification",
    component: PageSettingsAdminNotification,
    meta: { title: "Notification Settings" },
  },
  {
    path: "/:company_handle/settings/admin/xero/",
    name: "settings-admin-xero",
    component: PageSettingsAdminXero,
    meta: { title: "Xero Settings" },
  },
  {
    path: "/settings/crm/external-apis/",
    name: "crm-settings-external-apis",
    component: PageCrmSettingsExternalApis,
    meta: { title: "External APIs Settings" },
  },
  {
    path: "/settings/crm/sms-provider-settings/",
    name: "crm-settings-sms-provider",
    component: PageCrmSettingsSmsProvider,
    meta: { title: "SMS Provider Settings" },
  },
  {
    path: "/settings/crm/sms-provider-settings/add/",
    name: "crm-settings-add-sms-provider",
    component: PageAddCrmSettingsSmsProivder,
    meta: { title: "Add SMS Provider Settings" },
  },
  {
    path: "/settings/crm/sms-provider-settings/:settings_uuid/edit/",
    name: "crm-settings-edit-sms-provider",
    component: PageEditCrmSettingsSmsProvider,
    meta: { title: "Edit SMS Provider Settings" },
  },
  {
    path: "/settings/crm/sms-provider-settings/:settings_uuid/",
    name: "crm-settings-sms-provider-details",
    component: PageViewCrmSettingsSmsProvider,
    meta: { title: "SMS Provider Settings Details" },
  },
];

const router = new Router({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  let currentTitleArr = document.title.split("|");
  let suffix = currentTitleArr[currentTitleArr.length - 1];
  document.title = `${to.meta.title} | ${suffix}`;
  next();
});

export default router;
