import axios from "axios";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

const apiClient = axios.create({
  baseURL: process.env.BACKEND_HOST,
  headers: { "X-COMPANY-UUID": localStorage.getItem("COMPANY-UUID") },
});

const urlList = {
  urlRoot: "/api/v1/",
};

export default {
  // xero account
  getXeroAccountsList(perPage, page) {
    let url =
      urlList.urlRoot + "xero-accounts/?page_size=" + perPage + "&page=" + page;
    return apiClient.get(url);
  },

  getXeroAccountsListWithoutPagination() {
    let url = urlList.urlRoot + "xero-accounts/";
    return apiClient.get(url);
  },

  toggleXeroAccountStatus(objId) {
    let url = urlList.urlRoot + `xero-accounts/${objId}/toggle-status/`;
    return apiClient.patch(url);
  },

  syncXeroAccounts() {
    let url = urlList.urlRoot + "xero-accounts/sync-xero/";
    return apiClient.post(url);
  },

  // xero currency
  getXeroCurrenciesList(perPage, page) {
    let url =
      urlList.urlRoot +
      "xero-currencies/?page_size=" +
      perPage +
      "&page=" +
      page;
    return apiClient.get(url);
  },

  getXeroCurrenciesListWithoutPagination() {
    let url = urlList.urlRoot + "xero-currencies/";
    return apiClient.get(url);
  },

  setDefaultXeroCurrency(objId) {
    let url = urlList.urlRoot + `xero-currencies/${objId}/set-default/`;
    return apiClient.patch(url);
  },

  syncXeroCurrencies() {
    let url = urlList.urlRoot + "xero-currencies/sync-xero/";
    return apiClient.post(url);
  },

  // workshop invoices
  getWorkshopInvoicesList(perPage, page, searchQuery, customerUUID, statuses) {
    let url =
      urlList.urlRoot +
      "workshop/invoices/?page_size=" +
      perPage +
      "&page=" +
      page;

    if (searchQuery) {
      url += "&search=" + searchQuery;
    }

    if (customerUUID) {
      url += "&customer_uuid=" + customerUUID;
    }

    if (statuses) {
      url += "&statuses=" + statuses;
    }

    return apiClient.get(url);
  },

  getWorkshopInvoicesListWithoutPagination() {
    let url = urlList.urlRoot + "workshop/invoices/";
    return apiClient.get(url);
  },

  retrieveWorkshopInvoice(invoiceUUID) {
    let url = urlList.urlRoot + `workshop/invoices/${invoiceUUID}/`;
    return apiClient.get(url);
  },

  retrieveWorkshopInvoiceCustomerView(invoiceUUID) {
    let url =
      urlList.urlRoot + `workshop/invoices/${invoiceUUID}/customer-view/`;
    return apiClient.get(url);
  },

  createWorkshopInvoice(formData) {
    let url = urlList.urlRoot + "workshop/invoices/";
    return apiClient.post(url, formData);
  },

  checkWorkshopInvoiceCreateTaskStatus(formData) {
    let url = urlList.urlRoot + "workshop/invoices/create-task-status/";
    return apiClient.post(url, formData);
  },

  updateWorkshopInvoice(invoiceUUID, formData) {
    let url = urlList.urlRoot + `workshop/invoices/${invoiceUUID}/`;
    return apiClient.patch(url, formData);
  },

  getWorkshopInvoiceFormData() {
    let url = urlList.urlRoot + "workshop/invoices/form-data/";
    return apiClient.get(url);
  },

  shareWorkshopInvoiceWithCustomer(invoiceUUID, formData) {
    let url =
      urlList.urlRoot + `workshop/invoices/${invoiceUUID}/share/customer/`;
    return apiClient.post(url, formData);
  },

  addInvoiceLineItemFromMechanicScreen(invoiceUUID, formData) {
    let url =
      urlList.urlRoot +
      `workshop/invoices/${invoiceUUID}/mechanic-screen/invoice-line-item/`;
    return apiClient.post(url, formData);
  },

  updateInvoiceLineItemFromMechanicScreen(invoiceUUID, formData) {
    let url =
      urlList.urlRoot +
      `workshop/invoices/${invoiceUUID}/mechanic-screen/invoice-line-item/`;
    return apiClient.patch(url, formData);
  },

  deleteInvoiceLineItemFromMechanicScreen(invoiceUUID, formData) {
    let url =
      urlList.urlRoot +
      `workshop/invoices/${invoiceUUID}/mechanic-screen/invoice-line-item/`;
    return apiClient.delete(url, { data: formData });
  },

  generateWorkshopInvoicePDF(invoiceUUID, templateName) {
    let url =
      urlList.urlRoot + `workshop/invoices/${invoiceUUID}/generate-pdf/`;
    return apiClient.post(url, { template_name: templateName });
  },

  checkPDFGenerationTaskStatus(invoiceUUID, formData) {
    let url =
      urlList.urlRoot + `workshop/invoices/${invoiceUUID}/pdf-task-status/`;
    return apiClient.post(url, formData);
  },

  syncInvoiceInXero(invoiceUUID) {
    let url = urlList.urlRoot + `workshop/invoices/${invoiceUUID}/sync-xero/`;
    return apiClient.post(url);
  },

  checkXeroInvoiceTaskStatus(formData) {
    let url = urlList.urlRoot + `workshop/invoices/xero-task-status/`;
    return apiClient.post(url, formData);
  },
};
